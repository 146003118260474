import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useEffect } from 'react';

var useFullScreen = function useFullScreen(elRef) {
  var initialState = !process.env.__isBrowser__ ? false : isFullScreenElement(elRef);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      fullScreen = _useState2[0],
      setFullScreen = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      fullscreenEnabled = _useState4[0],
      setFullscreenEnabled = _useState4[1];

  var openFullScreen = function openFullScreen() {
    var el = elRef && elRef.current || document.documentElement;
    if (el.requestFullscreen) return el.requestFullscreen();
    if (el.enterFullscreen) return el.enterFullscreen();
    if (el.mozRequestFullScreen) return el.mozRequestFullScreen();
    if (el.webkitRequestFullscreen) return el.webkitRequestFullscreen();
    if (el.webkitEnterFullscreen) return el.webkitEnterFullscreen();
    if (el.msRequestFullscreen) return el.msRequestFullscreen();
  };

  function closeFullScreen() {
    if (document.exitFullscreen) return document.exitFullscreen();
    if (document.mozCancelFullScreen) return document.mozCancelFullScreen();
    if (document.webkitExitFullscreen) return document.webkitExitFullscreen();
    if (document.msExitFullscreen) return document.msExitFullscreen();
  }

  useEffect(function () {
    var handleChange = function handleChange() {
      setFullScreen(isFullScreenElement(elRef));
    };

    setFullscreenEnabled(isFullScreenElement());
    document.addEventListener('webkitfullscreenchange', handleChange, false);
    document.addEventListener('mozfullscreenchange', handleChange, false);
    document.addEventListener('msfullscreenchange', handleChange, false);
    document.addEventListener('MSFullscreenChange', handleChange, false); // IE11

    document.addEventListener('fullscreenchange', handleChange, false);
    return function () {
      document.removeEventListener('webkitfullscreenchange', handleChange);
      document.removeEventListener('mozfullscreenchange', handleChange);
      document.removeEventListener('msfullscreenchange', handleChange);
      document.removeEventListener('MSFullscreenChange', handleChange);
      document.removeEventListener('fullscreenchange', handleChange);
    };
  }, [elRef]);
  return {
    fullScreen: fullScreen,
    open: openFullScreen,
    close: closeFullScreen,
    toggle: fullScreen ? closeFullScreen : openFullScreen,
    fullscreenEnabled: fullscreenEnabled
  };
};

var isFullScreenElement = function isFullScreenElement(el) {
  if (el && el.current) {
    return Boolean(document.fullscreenElement === el.current || document.mozFullScreenElement === el.current || document.webkitFullscreenElement === el.current || document.msFullscreenElement === el.current);
  }

  return Boolean(document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled);
};

export default useFullScreen;